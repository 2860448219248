function initTexturedBackgrounds() {
  const root = document.documentElement;

  // how fast we progress through "time"
  let noiseStep = 0.00025;

  const backgroundHtml = `
          <div class="texturedBackground__background-container">
              <svg class="texturedBackground__circle--svg-one circleOne" viewBox="0 0 200 200">
                  <path class="texturedBackground__circle--path circlePathOne" d="" fill="url('#gradient')"></path>
              </svg>
              <svg class="texturedBackground__circle--svg-two circleTwo" viewBox="0 0 200 200">
                  <path class="texturedBackground__circle--path circlePathTwo" d="" fill="url('#gradient')"></path>
              </svg>
              <svg class="texturedBackground__circle--svg-three circleThree" viewBox="0 0 200 200">
                  <path class="texturedBackground__circle--path circlePathThree" d="" fill="url('#gradient')"></path>
              </svg>
              <svg class="texturedBackground__circle--svg-four circleFour" viewBox="0 0 200 200">
                  <path class="texturedBackground__circle--path circlePathFour" d="" fill="url('#gradient')"></path>
              </svg>
              <svg class="texturedBackground__circle--svg-five circleFive" viewBox="0 0 200 200">
                  <path class="texturedBackground__circle--path circlePathFive" d="" fill="url('#gradient')"></path>
              </svg>
          </div>`;

  for (let texturedSection of document.querySelectorAll(".content-section--textured")) {
    texturedSection.innerHTML += backgroundHtml;

    let pathOne = texturedSection.querySelector(
      ".texturedBackground__circle--svg-one .texturedBackground__circle--path"
    );
    let pathTwo = texturedSection.querySelector(
      ".texturedBackground__circle--svg-two .texturedBackground__circle--path"
    );
    let pathThree = texturedSection.querySelector(
      ".texturedBackground__circle--svg-three .texturedBackground__circle--path"
    );
    let pathFour = texturedSection.querySelector(
      ".texturedBackground__circle--svg-four .texturedBackground__circle--path"
    );
    let pathFive = texturedSection.querySelector(
      ".texturedBackground__circle--svg-five .texturedBackground__circle--path"
    );

    const points = createPoints();

    (function animate() {
      pathOne.setAttribute("d", spline(points, 1, true));
      pathTwo.setAttribute("d", spline(points, 1, true));
      pathThree.setAttribute("d", spline(points, 1, true));
      pathFour.setAttribute("d", spline(points, 1, true));
      pathFive.setAttribute("d", spline(points, 1, true));

      requestAnimationFrame(animate);

      for (let i = 0; i < points.length; i++) {
        const point = points[i];

        // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
        const nX = noise.simplex2(point.noiseOffsetX, point.noiseOffsetX);
        const nY = noise.simplex2(point.noiseOffsetY, point.noiseOffsetY);
        // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
        const x = map(nX, -1, 1, point.originX - 20, point.originX + 20);
        const y = map(nY, -1, 1, point.originY - 20, point.originY + 20);

        // update the point's current coordinates
        point.x = x;
        point.y = y;

        // progress the point's x, y values through "time"
        point.noiseOffsetX += noiseStep;
        point.noiseOffsetY += noiseStep;
      }
    })();
  }

  function createPoints(setPoints) {
    const points = [];
    // how many points do we need
    const numPoints = 8;
    // used to equally space each point around the circle
    const angleStep = (Math.PI * 2) / numPoints;
    // the radius of the circle
    const rad = 75;

    for (let i = 1; i <= numPoints; i++) {
      // x & y coordinates of the current point
      const theta = i * angleStep;

      const x = 100 + Math.cos(theta) * rad;
      const y = 100 + Math.sin(theta) * rad;

      // store the point
      points.push({
        x: x,
        y: y,
        /* we need to keep a reference to the point's original {x, y} coordinates 
  for when we modulate the values later */
        originX: x,
        originY: y,
        // more on this in a moment!
        noiseOffsetX: Math.random() * 10,
        noiseOffsetY: Math.random() * 10
      });
    }

    return points;
  }

  // map a number from 1 range to another
  function map(n, start1, end1, start2, end2) {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
    }
}