// Article Tabs
// Element to search for tabs within
// $('main').tabs({
// Enable dropdown on mobile.
//   dropdown: true,
// Dropdown to Tabs screen size
//   desktop: 1025,
// Enable animated tab indicator
//   animated: true
// });
(function ($) {
  $.fn.tabs = function (options) {

    // default plugin settings
    var settings = $.extend({
      dropdown: false,
      desktop: 1025,
      animated: false
    }, options);

    // gather plugin settings
    var tabs = this;
    var dropdown = settings.dropdown;
    var desktop = settings.desktop;
    var animated = settings.animated;
    var screen;

    // window resize function
    var waitForFinalEvent = (function () {
      var timers = {};
      return function (callback, ms, uniqueId) {
        if (!uniqueId) {
          uniqueId = "Don't call this twice without a uniqueId";
        }
        if (timers[uniqueId]) {
          clearTimeout(timers[uniqueId]);
        }
        timers[uniqueId] = setTimeout(callback, ms);
      };
    })();

    // settings based on window size
    function setSize() {
      screen = $(window).width();
      if (screen >= desktop) {
        spans = settings.spans;
        level2position = settings.level2position;
        level3position = settings.level3position;
      } else {
        spans = 'show';
        level2position = 'vertical-bottom';
        level3position = 'vertical-bottom';
      }
    }
    setSize();
    $(window).resize(function () {
      waitForFinalEvent(function () {
        screen = $(this);
        setSize();
      }, 100, "setSize");
    });

    function gatherTabs() {
      // Wrap each tab group in appropriate container
      var r = $.Deferred();
      if (dropdown === true && animated === true) {
        $(':not(.tab-content) + .tab-content, * > .tab-content:first-of-type').each(function () {
          $(this).nextUntil(':not(.tab-content)').addBack().wrapAll('<div class="tabs accordion animated" />');
        });
      } else if (animated === true) {
        $(':not(.tab-content) + .tab-content, * > .tab-content:first-of-type').each(function () {
          $(this).nextUntil(':not(.tab-content)').addBack().wrapAll('<div class="tabs animated" />');
        });
      } else if (dropdown === true) {
        $(':not(.tab-content) + .tab-content, * > .tab-content:first-of-type').each(function () {
          $(this).nextUntil(':not(.tab-content)').addBack().wrapAll('<div class="tabs accordion" />');
        });
      } else {
        $(':not(.tab-content) + .tab-content, * > .tab-content:first-of-type').each(function () {
          $(this).nextUntil(':not(.tab-content)').addBack().wrapAll('<div class="tabs" />');
        });
      }
      return r;
    }
    function makeTabs() {
      $('.tabs', tabs).each(function () {
        // The class for the container div
        var tabContainer = $(this);
        var tabContainerIndex = $(this).index();
        var tabsCount = $('.tabs .tab-content').length;
        var tabLabel = $('.tab-content:first-child', tabContainer).addClass('tab-current').find('> .tab-title').html();
        // The setup
        tabContainer.prepend($('<div class="tab-con"/>').prepend($('<ul class="tab-list" role="tablist" data-count="' + tabsCount + '"/>')));
        // if tabs have dropdown enabled insert dropdown label
        if (dropdown === true) {
          tabContainer.prepend('<span tabindex="0" class="tab-label">' + tabLabel + '</span>');
        }
        if (animated === true) {
          tabContainer.prepend('<span class="tab-indicator"></span>');
        }
        $('.tab-content', tabContainer).each(function () {
          var tabHeading = $(this).find('> .tab-title').html();
          var tabIcon = $(this).find('> .tab-icon').html();
          var tabHeadingID = 'tabs' + tabContainerIndex + 'tab' + $(this).index();
          if (tabIcon == null) {
            $('.tab-list', tabContainer).append('<li class="tab' + tabContainerIndex + '"><a href="#' + tabHeadingID + '">' + tabHeading + '</a></li>');
            $(this).attr('id', tabHeadingID);
          } else {
            $('.tab-list', tabContainer).append('<li class="tab' + tabContainerIndex + '"><a href="#' + tabHeadingID + '">' + tabIcon + tabHeading + '</a></li>');
            $(this).attr('id', tabHeadingID);
          }
        });

        if (animated === true) {
          var indicatorWidth = $('[role="tablist"] > li', tabContainer).width();
          $('.tab-indicator', tabContainer).css('width', indicatorWidth);
          $(window).resize(function () {
            indicatorWidth = $('[role="tablist"] > li', tabContainer).width();
            $('.tab-indicator', tabContainer).css('width', indicatorWidth);
          });
        }
        $('[role="tablist"] > li', tabContainer).attr('role', 'presentation');
        if (dropdown === true) {
          $('[role="tablist"] a', tabContainer).attr({
            'role': 'tab',
            'tabindex': '0'
          });
        } else {
          $('[role="tablist"] a', tabContainer).attr({
            'role': 'tab',
            'tabindex': '-1'
          });
        }
        // Make each aria-controls correspond id of targeted section (re href)
        $('[role="tablist"] a', tabContainer).each(function () {
          $(this).attr('aria-controls', $(this).attr('href').substring(1));
        });
        // Make the first tab selected by default and allow it focus
        $('[role="tablist"] li:first-child', tabContainer).addClass('tab-current');
        $('[role="tablist"] li:first-child a', tabContainer).attr({
          'aria-selected': 'true',
          'tabindex': '0'
        });
        // Make each section focusable and give it the tabpanel role
        $('section', tabContainer).attr({
          'role': 'tabpanel'
        });
        // Make first child of each panel focusable programmatically
        $('section > *:first-child', tabContainer).attr({
          'tabindex': '0'
        });
        // Make all but the first section hidden (ARIA state and display CSS)
        $('.tab-content:not(:first-of-type)', tabContainer).attr({
          'aria-hidden': 'true'
        });
        // Trigger label on keypress and add tabs open class
        $('.tab-label', tabContainer).on('click', function () {
          if ($('.tab-list', tabContainer).is(':visible')) {
            tabContainer.removeClass('tabs-open');
          } else {
            tabContainer.addClass('tabs-open');

          }
        });
        // Change focus between tabs with arrow keys
        $('[role="tab"]', tabContainer).on('keydown', function (e) {
          // define current, previous and next (possible) tabs
          var tabCurrent = $(this);
          var tabPrev = $(this).parents('li').prev().children('[role="tab"]');
          var tabNext = $(this).parents('li').next().children('[role="tab"]');
          var tabTarget;
          // find the direction (prev or next)
          if (dropdown === true) {
            switch (e.keyCode) {
              case 'shiftKey' + 9:
                tabTarget = tabPrev;
                break;
              case 9:
                tabTarget = tabNext;
                break;
              default:
                tabTarget = false
                break;
            }
          } else {
            switch (e.keyCode) {
              case 37:
                tabTarget = tabPrev;
                break;
              case 39:
                tabTarget = tabNext;
                break;
              default:
                tabTarget = false
                break;
            }
          }

          if (tabTarget.length) {
            if (dropdown === true) {
              tabCurrent.attr({
                'tabindex': '0',
                'aria-selected': null
              });
              tabTarget.attr({
                'tabindex': '0',
                'aria-selected': true
              }).focus();
            } else {
              tabCurrent.attr({
                'tabindex': '-1',
                'aria-selected': null
              });
              tabTarget.attr({
                'tabindex': '0',
                'aria-selected': true
              }).focus();
            }
          }
          // Hide panels
          $('.tab-content', tabContainer).attr('aria-hidden', 'true').removeClass('tab-current');
          // Show panel which corresponds to target
          $('#' + $(document.activeElement).attr('href').substring(1)).attr('aria-hidden', null).addClass('tab-current');
        });
        // Handle click on tab to show + focus tabpanel
        $('[role="tab"]', tabContainer).on('click', function (e) {
          e.preventDefault();
          var tab = $(this);
          var tabPanelID = $(this).attr('href');
          // var tabID = $(this).attr('aria-controls');
          var tabTitle = $(this).text();
          var tabOffset;
          // remove focusability and aria-selected
          if (dropdown === true && animated === true) {
            $('.tab-current', tabContainer).removeClass('tab-current');
            $(this, tabContainer).parent('li').addClass('tab-current');

            $('[role="tab"]', tabContainer).attr({
              'tabindex': '0',
              'aria-selected': null
            });
            $('.tab-label').text(tabTitle);
            tabContainer.removeClass('tabs-open');

            $('[role="tab"]', tabContainer).attr({
              'tabindex': '0',
              'aria-selected': null
            });
            $('.tab-label').text(tabTitle);

            tabOffset = $(this, tabContainer).parent('li').position();
            tabOffsetLeft = tabOffset.left + 'px';
            $('.tab-indicator', tabContainer).animate({
              left: tabOffsetLeft
            });
          } else if (animated === true) {
            tabOffset = $(this, tabContainer).parent('li').position();
          } else if (dropdown === true) {
            $('.tab-current', tabContainer).removeClass('tab-current');

            $(this, tabContainer).parent('li').addClass('tab-current');

            $('[role="tab"]', tabContainer).attr({
              'tabindex': '0',
              'aria-selected': null
            });
            $('.tab-label').text(tabTitle);
            tabContainer.removeClass('tabs-open');
          } else {
            $('.tab-current', tabContainer).removeClass('tab-current');

            $(this, tabContainer).parent('li').addClass('tab-current');

            $('[role="tab"]', tabContainer).attr({
              'tabindex': '-1',
              'aria-selected': null
            });
          }
          // replace above on clicked tab
          if (dropdown === true) {
            $(this).attr({
              'aria-selected': true,
            });
            $(this).parent('li').addClass('tab-current');
          } else {
            $(this).attr({
              'aria-selected': true,
              'tabindex': '0'
            });
            $(this).parent('li').addClass('tab-current');
          }
          // Hide panels
          $('.tab-content', tabContainer).attr('aria-hidden', 'true').removeClass('tab-current');
          // show corresponding panel
          $('section' + tabPanelID).attr('aria-hidden', null).addClass('tab-current');
        });

        //Remove Animated class if other type of Tabs
        if ($('.tab-content.tab-open--underline').length) {
          $('.tab-content.tab-open--underline').parent().addClass('tab-open--underline').removeClass('animated');
          $('.tabs.tab-open--underline .tab-indicator').remove();
        }

        if ($('.tab-content.classic').length) {
          $('.tab-content.classic').parent().addClass('classic').removeClass('animated');
          $('.tabs.classic .tab-indicator').remove();
        }

        if ($('.tab-content.tab-open').length) {
          $('.tab-content.tab-open').parent().addClass('tab-open').removeClass('animated');
          $('.tabs.tab-open .tab-indicator').remove();
        }

        if ($('.tab-content.icon').length) {
          $('.tab-content.icon').parent().addClass('icon').removeClass('animated');
          $('.tabs.icon .tab-indicator').remove();
        }
        //Remove Animated class if other type of Tabs - END

        // wrap tabs
        var wrapTabs = $('.tab-con', tabContainer);
        var tabsContainerWidth = $('.tab-con', tabContainer).outerWidth();
        var tabsWidth = $('.tab-list', tabContainer).outerWidth();
        var scrollWidth = tabsWidth - tabsContainerWidth;

        if (tabsWidth > tabsContainerWidth) {
          tabContainer.addClass('scroll-right');
          wrapTabs.on('scroll', function () {
            if (wrapTabs.scrollLeft() >= tabsWidth - tabsContainerWidth) {
              tabContainer.removeClass('scroll-right');
            } else {
              tabContainer.addClass('scroll-right');
            }
            if (wrapTabs.scrollLeft() > 1) {
              tabContainer.addClass('scroll-left');
            } else {
              tabContainer.removeClass('scroll-left');
            }
          });
        }
      });
    }
    gatherTabs().done(makeTabs());
  };
}(jQuery));