
//SafeLinks array example
// var SafeLinks = [
//   '[href*=".{{prod-domain}}.com"]',
//   '[href^="https://{{prod-domain}}.com"]',
//   '[href^="http://{{prod-domain}}.com"]',
//   '[href^="http://www.{{prod-domain}}.com"]',
//   '[href^="http://{{demo-domain}}.lrsws.co/"]',
//   '[href^="https://{{demo-domain}}.lrsws.co/"]'
// ];

function initSpeedbump(SafeLinks) {
  // automatic speedbump
  // find all speedbump enabled external links
  var link,
    DefaultSafeLinks = [
      '[href=""]',
      '[href^="tel"]',
      '[href^="/"]',
      '[href^="#"]',
      '[class*="no-speedbump"]',
      '[id*="url-link"]',
      '[id*="close-speedbump"]',
      '[class*="close-reveal-modal"]',
      '[class*="btn-search"]'];

  var selector = "a:not(";

  $(DefaultSafeLinks).each(function (index) {
    selector += DefaultSafeLinks[index] + ", ";
  });

  $(SafeLinks).each(function (index) {
    selector += SafeLinks[index] + ", ";
  });

  selector = selector.substring(0, selector.length - 2) + ")";

  $(selector).addClass('external');

  $('[href^="mailto:"]').addClass('email-link').removeClass('external');

  // remove external class from links without href attribute
  $('a:not([href])').removeClass('external');

  // add the link and title to the continue button
  $('body').on('click', 'a.external', function (e) {
    e.preventDefault();
    if (!$(this).hasClass('no-redirect')) {
      var url = $(this).attr('href');
      var target = $(this).attr('target');
      $('#url-link').attr('href', url).attr('target', target);
      $('#em_url-link').attr('href', url).attr('target', target);
      $('.modal-overlay').addClass('show-modal');
      $('#speedbump').addClass('open');
      $('#speedbump').addClass('show-modal');
      $('#speedbump').removeAttr('hidden').attr('aria-hidden', 'false').attr('tabindex', '0');
      $('.modal-content .close-modal').addClass('modal-inline').attr('tabindex', '0');
      // close reveal if overlay is clicked
      e.stopPropagation();
      if ($('#speedbump').hasClass('open')) {
        $(document).on('click', function () {
          $('#speedbump').removeClass('open');
          $('#speedbump').removeClass('show-modal');
          $('#speedbump').attr('hidden', 'true').attr('aria-hidden', 'true').attr('tabindex', '');
          $('.modal-overlay').removeClass('show-modal');
        });
        $(".modal-overlay").on('click', function (event) {
          alert('clicked inside');
          event.stopPropagation();
        });
        $(document).on('keydown', function (event) {
          if (event.key == "Escape") {
            $('#speedbump').removeClass('open');
            $('#speedbump').removeClass('show-modal');
            $('#speedbump').attr('hidden', 'true').attr('aria-hidden', 'true').attr('tabindex', '');
            $('.modal-overlay').removeClass('show-modal');
            $(".modal-overlay").on('click', function (event) {
              alert('clicked inside');
              event.stopPropagation();
            });
          }
        });
      }
    }
  });

  // add the link and title to the continue button for PDFs
  $('body').on('click', 'a.external-pdf', function (e) {
    e.preventDefault();
    if (!$(this).hasClass('no-redirect')) {
      var url = $(this).attr('href');
      var target = $(this).attr('target');
      $('#url-link-pdf').attr('href', url).attr('target', target);
      $('#em_url-link').attr('href', url).attr('target', target);
      $('.modal-overlay').addClass('show-modal');
      $('#speedbumpPDF').addClass('open');
      $('#speedbumpPDF').addClass('show-modal');
      $('#speedbumpPDF').removeAttr('hidden').attr('aria-hidden', 'false').attr('tabindex', '0');
      $('.modal-content .close-modal').addClass('modal-inline').attr('tabindex', '0');
      // close reveal if overlay is clicked
      e.stopPropagation();
      if ($('#speedbumpPDF').hasClass('open')) {
        $(document).on('click', function () {
          $('#speedbumpPDF').removeClass('open');
          $('#speedbumpPDF').removeClass('show-modal');
          $('#speedbumpPDF').attr('hidden', 'true').attr('aria-hidden', 'true').attr('tabindex', '');
          $('.modal-overlay').removeClass('show-modal');
        });
        $(".modal-overlay").on('click', function (event) {
          alert('clicked inside');
          event.stopPropagation();
        });
        $(document).on('keydown', function (event) {
          if (event.key == "Escape") {
            $('#speedbumpPDF').removeClass('open');
            $('#speedbumpPDF').removeClass('show-modal');
            $('#speedbumpPDF').attr('hidden', 'true').attr('aria-hidden', 'true').attr('tabindex', '');
            $('.modal-overlay').removeClass('show-modal');
            $(".modal-overlay").on('click', function (event) {
              alert('clicked inside');
              event.stopPropagation();
            });
          }
        });
      }
    }
  });

  // add the link and title to the continue button for Emails
  $('body').on('click', 'a.email-link', function (e) {
    e.preventDefault();
    if (!$(this).hasClass('no-redirect')) {
      var url = $(this).attr('href');
      var target = $(this).attr('target');
      $('#url-link-email').attr('href', url).attr('target', target);
      $('#em_url-link').attr('href', url).attr('target', target);
      $('.modal-overlay').addClass('show-modal');
      $('#speedbumpEmail').addClass('open');
      $('#speedbumpEmail').addClass('show-modal');
      $('#speedbumpEmail').removeAttr('hidden').attr('aria-hidden', 'false').attr('tabindex', '0');
      $('.modal-content .close-modal').addClass('modal-inline').attr('tabindex', '0');
      // close reveal if overlay is clicked
      e.stopPropagation();
      if ($('#speedbumpEmail').hasClass('open')) {
        $(document).on('click', function () {
          $('#speedbumpEmail').removeClass('open');
          $('#speedbumpEmail').removeClass('show-modal');
          $('#speedbumpEmail').attr('hidden', 'true').attr('aria-hidden', 'true').attr('tabindex', '');
          $('.modal-overlay').removeClass('show-modal');
        });
        $(".modal-overlay").on('click', function (event) {
          alert('clicked inside');
          event.stopPropagation();
        });
        $(document).on('keydown', function (event) {
          if (event.key == "Escape") {
            $('#speedbumpEmail').removeClass('open');
            $('#speedbumpEmail').removeClass('show-modal');
            $('#speedbumpEmail').attr('hidden', 'true').attr('aria-hidden', 'true').attr('tabindex', '');
            $('.modal-overlay').removeClass('show-modal');
            $(".modal-overlay").on('click', function (event) {
              alert('clicked inside');
              event.stopPropagation();
            });
          }
        });
      }
    }
  });

  // close speedbump when clicking acknowledgment button
  $('#speedbump a#close-speedbump, ' +
    '.close-modal, ' +
    '#speedbump a#close-reveal-modal, ' +
    '#email_speedbump a#em_close-speedbump, ' +
    '#email_speedbump a#em_close-reveal-modal, ' +
    '#login_speedbump a#em_close-speedbump, ' +
    '#login_speedbump a#em_close-reveal-modal,  ' +
    '#login_speedbump_mobile a#em_close-speedbump, ' +
    '#login_speedbump_mobile a#em_close-reveal-modal').on('click', function (e) {
      e.preventDefault();
      $('#speedbump').removeClass('open');
      $('#speedbump').removeClass('show-modal');
      $('#speedbump').attr('hidden', 'true').attr('aria-hidden', 'true').attr('tabindex', '');
      $('.modal-overlay').removeClass('show-modal');
    });

  // close reveal if link is clicked
  $('a:not([id*="close-speedbump"], ' +
    '[id*="close-reveal-modal"], ' +
    '[id*="em_close-speedbump"], ' +
    '[id*="em_close-reveal-modal"])').on('click', function () {
      $('#speedbump').removeClass('open');
      $('#speedbump').removeClass('show-modal');
      $('#speedbump').attr('hidden', 'true').attr('aria-hidden', 'true').attr('tabindex', '');
      $('.modal-overlay').removeClass('show-modal');
    });

  // close speedbumpPDF when clicking acknowledgment button for PDFs
  $('#speedbumpPDF a#close-speedbump, ' +
    '.close-modal, ' +
    '#speedbumpPDF a#close-reveal-modal, ' +
    '#email_speedbump a#em_close-speedbump, ' +
    '#email_speedbump a#em_close-reveal-modal, ' +
    '#login_speedbump a#em_close-speedbump, ' +
    '#login_speedbump a#em_close-reveal-modal,  ' +
    '#login_speedbump_mobile a#em_close-speedbump, ' +
    '#login_speedbump_mobile a#em_close-reveal-modal').on('click', function (e) {
      e.preventDefault();
      $('#speedbumpPDF').removeClass('open');
      $('#speedbumpPDF').removeClass('show-modal');
      $('#speedbumpPDF').attr('hidden', 'true').attr('aria-hidden', 'true').attr('tabindex', '');
      $('.modal-overlay').removeClass('show-modal');
    });

  // close reveal if link is clicked for PDFs
  $('a:not([id*="close-speedbump"], ' +
    '[id*="close-reveal-modal"], ' +
    '[id*="em_close-speedbump"], ' +
    '[id*="em_close-reveal-modal"])').on('click', function () {
      $('#speedbumpPDF').removeClass('open');
      $('#speedbumpPDF').removeClass('show-modal');
      $('#speedbumpPDF').attr('hidden', 'true').attr('aria-hidden', 'true').attr('tabindex', '');
      $('.modal-overlay').removeClass('show-modal');
    });

  // close speedbumpEmail when clicking acknowledgment button for Emails
  $('#speedbumpEmail a#close-speedbump, ' +
    '.close-modal, ' +
    '#speedbumpEmail a#close-reveal-modal, ' +
    '#email_speedbump a#em_close-speedbump, ' +
    '#email_speedbump a#em_close-reveal-modal, ' +
    '#login_speedbump a#em_close-speedbump, ' +
    '#login_speedbump a#em_close-reveal-modal,  ' +
    '#login_speedbump_mobile a#em_close-speedbump, ' +
    '#login_speedbump_mobile a#em_close-reveal-modal').on('click', function (e) {
      e.preventDefault();
      $('#speedbumpEmail').removeClass('open');
      $('#speedbumpEmail').removeClass('show-modal');
      $('#speedbumpEmail').attr('hidden', 'true').attr('aria-hidden', 'true').attr('tabindex', '');
      $('.modal-overlay').removeClass('show-modal');
    });

  // close reveal if link is clicked for PDFs
  $('a:not([id*="close-speedbump"], ' +
    '[id*="close-reveal-modal"], ' +
    '[id*="em_close-speedbump"], ' +
    '[id*="em_close-reveal-modal"])').on('click', function () {
      $('#speedbumpEmail').removeClass('open');
      $('#speedbumpEmail').removeClass('show-modal');
      $('#speedbumpEmail').attr('hidden', 'true').attr('aria-hidden', 'true').attr('tabindex', '');
      $('.modal-overlay').removeClass('show-modal');
    });

}
